import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsGeneral } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTiposIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { alone, getListTipoIncidente, getPagination, listIncidentes, setPagination } from "../../../store/slices/tipoIncidentes";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRow } from "../../molecule";
import { HeaderVista } from "../../organism";

const IncidenciasTemplate = () => {
  const storePage = useSelector(getPagination, shallowEqual);
  const dataTipoIncidencias = useSelector(getListTipoIncidente, shallowEqual);
  const [getIncidencias, { isLoading: isLoadingIncidencias, isSuccess: isSuccessIncidencias, data: dataIncidencias }] = useGetTiposIncidenteMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccessIncidencias) {
      const data = dataIncidencias?.data;
      dispatch(listIncidentes(data.docs));
      dispatch(setPagination(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessIncidencias]);

  useEffect(() => {
    dispatch(alone());
  }, []);

  useEffect(() => {
    let newPagination = {
      limit: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getIncidencias(newPagination);
  }, [storePage.page, storePage.limit]);

  if (isLoadingIncidencias) return <Loading />;

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_INCIDENT} children="Nuevo" />} titulo="Tipos de incidencias comunas" />

      <DataTable columns={columnsGeneral} rows={dataTipoIncidencias} RowComponent={TableRow} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_INCIDENT}`} />
      <br />
    </Container>
  );
};

export default IncidenciasTemplate;
