import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ButtonIcon from "../../components/elementos/ButtonIcon2";
import { DataTable, Loading, TableRowEmpresas } from "../../components/molecule";
import { HeaderVista } from "../../components/organism";
import { columnsEmpresa } from "../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { useGetEmpresasMutation } from "../../store/api/endpoints/empresaApiSlice";
import { clean, getListEmpresas, getPagination, setList, setPagination } from "../../store/slices/empresa";

const EmpresaScreen = () => {
  const [getEmpresas, { isLoading, isSuccess, data }] = useGetEmpresasMutation();
  const dispatch = useDispatch();
  const storePage = useSelector(getPagination, shallowEqual);
  const empresas = useSelector(getListEmpresas, shallowEqual);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setPagination(data?.data));
      dispatch(setList(data?.data.docs));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    let newPagination = {
      cantRows: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getEmpresas({
      ...newPagination,
    });
  }, [storePage?.page, storePage?.limit]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_EMPRESA} children="Nuevo" />} titulo="Listado de usuarios empresas" />
      <DataTable columns={columnsEmpresa} rows={empresas} RowComponent={TableRowEmpresas} pagination={storePage} label="Datos" handlePagination={setPagination} to={`${PRIVATE_ROUTES.FICHA_EMPRESA}`} />
      <br />
    </Container>
  );
};

export default EmpresaScreen;
