import { EstadoEditTemplate } from "../../components/templates";

const EstadoEditScreen = () => {
  return (
    <div>
      <EstadoEditTemplate />
    </div>
  );
};

export default EstadoEditScreen;
