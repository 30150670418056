import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { alone } from "../../../store/slices/sucesos";
import { convertDateUtc } from "../../../utils/formatDate";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import ListInformeGeneral from "../../organism/suceso/ListInformGeneral";
import ListInformeComuna from "../../organism/suceso/ListInformeComuna";
import ListInformeEmpresa from "../../organism/suceso/ListInformeEmpresa";
import moment from "moment";

const SucesoFichaTemplate = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getSuceso, responseSuceso] = useGetSucesoMutation();
  const id = searchParams.get("id");
  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = { ...responseSuceso?.data?.data };
      let dateFormated = convertDateUtc(sucesoFormatted?.fechahora);
      let splitFecha = dateFormated?.split(" ");
      sucesoFormatted.fecha = moment(splitFecha[0]).format("DD-MM-YYYY");
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess]);

  const fetchSuceso = useCallback(() => {
    if (id) {
      getSuceso({ id, isFicha: true });
    }
  }, [id, getSuceso]);

  useEffect(() => {
    fetchSuceso();
  }, []);

  if (responseSuceso.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_SUCESOS} />}
        titulo={"Ficha del suceso"}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_SUCESOS}?id=${id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
      />
      <main>
        <ListInformeGeneral />
        <ListInformeComuna />
        <ListInformeEmpresa />
        <br />
      </main>
    </Container>
  );
};

export default SucesoFichaTemplate;
