import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { HeaderVista } from "../../components/organism";
import { DataTable, Loading } from "../../components/molecule";
import { columnsIndicadores } from "../../constants/TitleTables";
import { TableRowIndicadores } from "../../components/molecule/TableRowIndicadores";
import { getFilters, getPagination, setfilters, setPagination } from "../../store/slices/sucesos";
import { useGetCantidadSucesosPorFechaQuery, useGetSucesosPorComunasQuery } from "../../store/api/endpoints/sucesosApiSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@mui/material";
import { ButtonIcon } from "../../components/atoms";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import IndicadorFechas from "../../components/organism/home/IndicadorFechas";
import { listIncidentes } from "../../store/slices/tipoIncidentes";
import { list } from "../../store/slices/tipoCortes";
import { formatTypes } from "../../utils/formatData";
import { useGetTipoCortesMutation } from "../../store/api/endpoints/tipocortesApiSlice";
import { useGetStatesMutation } from "../../store/api/endpoints/stateApiSlice";
import { useGetTiposIncidenteMutation } from "../../store/api/endpoints/tipoIncidenteApiSlice";
import { ListEstados } from "../../store/slices/estado";
const Filter = lazy(() => import("../../components/organism/Filter/Filter"));

const HomeScreen = () => {
  const storePage = useSelector(getPagination, shallowEqual);
  const [modalFilter, setModalFilter] = useState(false);
  const dispatch = useDispatch();
  const tableTitle = useMemo(() => columnsIndicadores, []);
  const [sucesos, setSucesos] = useState([]);
  const filters = useSelector(getFilters, shallowEqual);

  const { data, isLoading, isSuccess } = useGetSucesosPorComunasQuery(filters);
  const { data: sucesoCantidadPorFecha, isLoading: isLoadingCantidadPorFecha } = useGetCantidadSucesosPorFechaQuery(filters);
  const [getCortes, { isLoading: isLoadingCortes, isSuccess: isSuccessCortes, data: dataCortes }] = useGetTipoCortesMutation();
  const [getEstados, { isLoading: isLoadingEstados, isSuccess: isSuccessEstados, data: dataEstados }] = useGetStatesMutation();
  const [getIncidentes, { isLoading: isLoadingIncidentes, isSuccess: isSuccessIncidentes, data: dataIncidentes }] = useGetTiposIncidenteMutation();

  useEffect(() => {
    getCortes({ limit: 50, page: 0 });
    getEstados({ limit: 50, page: 0 });
    getIncidentes({ limit: 50, page: 0 });
  }, []);

  useEffect(() => {
    if (isSuccessIncidentes) {
      dispatch(listIncidentes(formatTypes(dataIncidentes?.data?.docs)));
    }
    if (isSuccessCortes) {
      dispatch(list(formatTypes(dataCortes?.data?.docs)));
    }
    if (isSuccessEstados) {
      const data = dataEstados?.data?.docs;
      dispatch(ListEstados(formatTypes(data)));
    }
  }, [isSuccessCortes, isSuccessIncidentes, isSuccessEstados]);

  const handleSave = async (newFilters) => {
    setModalFilter(false);
    dispatch(setfilters(newFilters));
  };

  const handleChangeModal = useCallback(() => setModalFilter((prev) => !prev), []);

  useEffect(() => {
    if (isSuccess) {
      const dataIndicadoresSucesos = data?.data;
      let newData = [...dataIndicadoresSucesos?.datos];
      newData.push({ totalSinAsignarYSinEmpresa: dataIndicadoresSucesos?.totalSinAsignarYSinEmpresa, totalSinEstado: dataIndicadoresSucesos?.totalSinEstado, totalSinEmpresa: dataIndicadoresSucesos?.totalSinEmpresa });
      setSucesos(newData);
    }
  }, [isSuccess, data]);

  if (isLoading || isLoadingCantidadPorFecha || isLoadingCortes || isLoadingEstados || isLoadingIncidentes) return <Loading />;
  return (
    <Container>
      <HeaderVista
        titulo="Indicadores"
        start={
          <ButtonIcon size={"big"} variant={"outlined"} aria-label="Filter" noLink disabled={false} startIcon={<FilterAltIcon />} handleClick={handleChangeModal}>
            Filtrar
          </ButtonIcon>
        }
      />
      <Grid container style={{ display: "flex", flexDirection: "column" }}>
        <Grid item xs={12} md={12} styles={{ height: "300px" }}>
          <IndicadorFechas sucesos={sucesoCantidadPorFecha?.data} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataTable columns={tableTitle} rows={sucesos} RowComponent={TableRowIndicadores} enablePaginate={false} pagination={storePage} label="Datos" handlePagination={setPagination} />
        </Grid>
      </Grid>
      {modalFilter && (
        <Suspense fallback={<Loading />}>
          <Filter handleSave={handleSave} modal={modalFilter} pagination={storePage} setModalFilter={setModalFilter} />
        </Suspense>
      )}
    </Container>
  );
};

export default HomeScreen;
