import SearchIcon from "@mui/icons-material/Search";
import { ButtonIcon } from "../atoms";

export const TableRowEstadoEmpresa = ({ row, to }) => (
  <tr style={{ height: "10%" }}>
    <td align="right">
      <p>{row.codigo}</p>
    </td>
    <td align="left">
      <p>{row.nombre}</p>
    </td>
    <td align="center">
      <p>{row.vuelve_a_comuna ? "Si" : "No"}</p>
    </td>
    <td align="left">
      <ButtonIcon arialLabel="detailsUser" startIcon={<SearchIcon />} to={to} marginRight />
    </td>
  </tr>
);
