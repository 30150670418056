import { TipoDanioEditTemplate } from "../../components/templates";

const TipoDanioEditScreen = () => {
  return (
    <div>
      <TipoDanioEditTemplate />
    </div>
  );
};

export default TipoDanioEditScreen;
