import SearchIcon from "@mui/icons-material/Search";
import { ButtonIcon } from "../atoms";

export const TableRowEstado = ({ row, to }) => (
  <tr style={{ height: "10%" }}>
    <td align="center">
      <p style={{ backgroundColor: !row.color ? "black" : row.color, borderRadius: "50%", height: "17px", width: "17px" }}></p>
    </td>
    <td align="right">
      <p>{row.codigo}</p>
    </td>
    <td align="left">
      <p>{row.nombre}</p>
    </td>
    <td align="center">
      <p>{row.elige_empresa ? "Si" : "No"}</p>
    </td>
    <td align="center">
      <p>{row.estado_final ? "Si" : "No"}</p>
    </td>
    <td align="left">
      <ButtonIcon arialLabel="detailsUser" startIcon={<SearchIcon />} to={to} marginRight />
    </td>
  </tr>
);
