import { CorteDeCalleEmpresasFichaTemplate } from "../../components/templates";

const CorteDeCallesEmpresasFichaScreen = () => {
  return (
    <div>
      <CorteDeCalleEmpresasFichaTemplate />
    </div>
  );
};

export default CorteDeCallesEmpresasFichaScreen;
