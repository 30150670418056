/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import "../../../assets/css/Navbar.css";
import logoBA from "../../../assets/images/logo.webp";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../../../routes/name-path.routes";
import { logOut } from "../../../store/slices/auth";
import { changeStatusSidebar } from "../../../store/slices/sidebar";
import useComponentVisible from "../../elementos/useVisible";
import { clean } from "../../../store/slices/sucesos";
import { useState } from "react";

export const Navbar = () => {
  const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleMenu = (link) => {
    dispatch(changeStatusSidebar(!isComponentVisible));
    setIsComponentVisible(!isComponentVisible);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    handleCloseUserMenu();
    dispatch(logOut());
    navigate(PUBLIC_ROUTES.Login);
  };

  const handleNavigate = () => {
    dispatch(clean());
    navigate(PRIVATE_ROUTES.HOME);
  };

  const btnLeft = (
    <Button onClick={handleToggleMenu} aria-label="Menu">
      <MenuIcon />
    </Button>
  );

  const btnRight = (
    <div style={{ zIndex: 100 }}>
      <Tooltip title="Configuración">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={"Usuario"} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link to={PRIVATE_ROUTES.SELF} onClick={handleCloseUserMenu}>
          <MenuItem>
            <Typography textAlign="center" component="p">
              Mi Perfil
            </Typography>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogOut}>
          <Typography textAlign="center" component="p">
            Salir
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>{token ? btnLeft : ""}</Box>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            <img async loading="lazy" fetchpriority="low" src={logoBA} onClick={handleNavigate} height="65px" width="97.5px" alt="Logo BA" />
          </Typography>
          <Box sx={{ flexGrow: 0 }}>{token ? btnRight : ""}</Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
