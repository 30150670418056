import { UserNewTemplate } from "../../components/templates";

const UserNewScreen = () => {
  return (
    <div>
      <UserNewTemplate />
    </div>
  );
};

export default UserNewScreen;
