import { TipoDanioFichaTemplate } from "../../components/templates";

const TipoDanioFichaScreen = () => {
  return (
    <div>
      <TipoDanioFichaTemplate />
    </div>
  );
};

export default TipoDanioFichaScreen;
