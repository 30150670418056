import { TipoNombreCalleFichaTemplate } from "../../components/templates";

const TipoDanioFichaScreen = () => {
  return (
    <div>
      <TipoNombreCalleFichaTemplate />
    </div>
  );
};

export default TipoDanioFichaScreen;
