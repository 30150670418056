/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";
import { useSelector } from "react-redux";
import "../../../assets/css/Sidebar.css";
import "../../../assets/css/dropdown.css";
import { SidebarData } from "../../../constants/SidebarData";
import { getStatusSidebar } from "../../../store/slices/sidebar";
import SubMenu from "./Dropdown";
import { NOMBRE_APP } from "../../../constants/global";
import { Divider } from "@mui/material";

export const Sidebar = memo(() => {
  const statusSidebar = useSelector(getStatusSidebar);

  return (
    <div id="sidebar" className={statusSidebar ? "open" : "closed"}>
      <h3 style={{ padding: "0px 10px" }}>{NOMBRE_APP}</h3>
      <Divider />
      {SidebarData.map((el, index) => (
        <SubMenu item={el} key={index} />
      ))}
    </div>
  );
});
