import { memo } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import styles from "./IndicadorFechas.module.css";

Chart.register(CategoryScale);

const IndicadorFechas = memo(({ sucesos }) => {
  const labels = sucesos?.map((el) => el._id)?.reverse();
  const data = sucesos?.map((el) => el.count)?.reverse();
  const chartData = { labels, datasets: [{ label: "Cantidad de Llamados", data, borderColor: "#808080", backgroundColor: "rgba(0, 123, 255, 0.5)" }] };
  const options = {
    plugins: {
      itle: {
        display: true,
        text: "Cantidad de llamadas por fechas",
      },
      legend: { display: false },
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  return (
    <div className={styles["container-indicador"]}>
      <Line data={chartData} options={options} />
    </div>
  );
});

export default IndicadorFechas;
