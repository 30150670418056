import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsGeneral } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTipoDaniosMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { alone, getListTipoDanio, getPagination, listDanio, setPagination } from "../../../store/slices/tipoDanio";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRow } from "../../molecule";
import { HeaderVista } from "../../organism";

const TipoDanioTemplate = () => {
  const dispatch = useDispatch();
  const [getDanios, { isLoading, isSuccess, data: dataDanios }] = useGetTipoDaniosMutation();
  const storePage = useSelector(getPagination, shallowEqual);
  const listDanios = useSelector(getListTipoDanio, shallowEqual);
  useEffect(() => {
    if (isSuccess) {
      const data = dataDanios.data;
      dispatch(listDanio(data.docs));
      dispatch(setPagination(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    dispatch(alone());
  }, []);

  useEffect(() => {
    let newPagination = {
      limit: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getDanios({ ...newPagination });
  }, [storePage.page, storePage.limit]);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_TIPO_DAÑO_EMPRESAS} children="Nuevo" />} titulo="Tipos de daños" />
      <DataTable columns={columnsGeneral} rows={listDanios} RowComponent={TableRow} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_TIPO_DAÑO_EMPRESAS}`} />
      <br />
    </Container>
  );
};

export default TipoDanioTemplate;
