/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { alone, getTipoDanio } from "../../../store/slices/tipoDanio";
import { ButtonIcon, Divider, Input } from "../../atoms";

const FormCortesDeCalle = ({ onSubmit, editting }) => {
  const cortes = useSelector(getTipoDanio, shallowEqual);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");

  const handleCancel = () => {
    let redirect = `${PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS}`;
    if (id) {
      redirect = `${PRIVATE_ROUTES.FICHA_TIPO_DAÑO_EMPRESAS}?id=${id}`;
    }
    navigate(redirect);
  };

  const changeValue = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  useEffect(() => {
    if (cortes) {
      sessionStorage.setItem("creandoCorte", JSON.stringify(cortes));
    }
  }, [cortes]);

  const handleSave = () => {
    onSubmit();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <Input label="Nombre" icon={<PersonIcon />} value={cortes?.nombre ? cortes?.nombre : ""} name="nombre" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSave} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default FormCortesDeCalle;
