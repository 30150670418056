import { Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useCreateTipoDanioMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { TipoDañoNew } from "../../organism/tipoDanio/TipoDañoNew";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";

const TipoDanioNewTemplate = () => {
  const [searchParams] = useSearchParams();
  const [create, response] = useCreateTipoDanioMutation();
  const id = searchParams.get("id");

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" to={`${PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS}`} />} titulo={"Crear tipo de daño"} end={<></>} />
      <TipoDañoNew id={id} createMutation={create} responseMutation={response} />
      <br />
    </Container>
  );
};

export default TipoDanioNewTemplate;
