/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LIST_COMUNAS } from "../../../constants/filterSucesos";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useCreateSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { alone, getSuceso } from "../../../store/slices/sucesos";
import { convertDateUtc } from "../../../utils/formatDate";
import { ButtonIcon } from "../../atoms";
import { DatePickerCustom, Loading, SelectSmall, SmallInput, TimePickerCustom } from "../../molecule";
import { style } from "./styles";
import { openFeedback } from "../../../store/slices/feedback";

const FormNewSuceso = () => {
  const suceso = useSelector(getSuceso, shallowEqual);
  const [createSuceso, responseSuceso] = useCreateSucesoMutation();
  const listComunas = LIST_COMUNAS.map((el) => ({ ...el, key: el.value }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  const isSucesoComplete = () => {
    return suceso?.fecha && suceso?.hora && suceso?.domicilio && suceso?.comuna && suceso?.telefono_vecino && suceso?.relato && suceso?.subtipo;
  };

  const handleSave = () => {
    if (!isSucesoComplete()) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Falta completar campos!",
        })
      );
    } else {
      const newDate = moment.tz(`${suceso.fecha} ${suceso?.hora}`, "DD-MM-YYYY HH:mm:ss", "America/Argentina/Buenos_Aires").format();
      let newSuceso = {
        ...suceso,
        fechahora: newDate,
        carga_administrador: null,
        datos_comuna: {
          calle: "",
          especie_arbol: "",
        },
      };
      createSuceso(newSuceso);
    }
  };

  const handleCancel = () => {
    navigate(PRIVATE_ROUTES.LIST_SUCESOS);
  };

  const onChangeFechaHora = (fechaTemp, horaTemp) => {
    if (fechaTemp) {
      dispatch(alone({ ["fecha"]: fechaTemp }));
    }
    if (horaTemp) {
      dispatch(alone({ ["hora"]: horaTemp }));
    }
  };

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      navigate(PRIVATE_ROUTES.LIST_SUCESOS);
    }
  }, [responseSuceso.isSuccess]);

  if (responseSuceso.isLoading) return <Loading />;

  return (
    <List sx={style} aria-label="mailbox folders">
      <List sx={style} aria-label="mailbox folders">
        <ListItem>
          <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe General</Typography>} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha Llamado:</Typography>} />
          <DatePickerCustom value={suceso?.fecha} required={true} handleChange={(e) => onChangeFechaHora(e, false)} name="fecha" seterror={() => {}} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Hora llamado:</Typography>} />
          <TimePickerCustom value={suceso?.hora} required={true} handleChange={(e) => onChangeFechaHora(false, e)} name="hora" seterror={() => {}} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Domicilio:</Typography>} />
          <SmallInput restricciones={["alfaNumerico"]} required={true} value={suceso?.domicilio} onChange={onChange} name={"domicilio"} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Comuna:</Typography>} />
          <SelectSmall lista={listComunas} required={true} value={suceso?.comuna ?? ""} name={"comuna"} onChange={onChange} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Telefono vecino:</Typography>} />
          <SmallInput restricciones={["alfaNumerico"]} required={true} value={suceso?.telefono_vecino} onChange={onChange} name={"telefono_vecino"} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Ingrese el relato.:</Typography>} />
          <SmallInput restricciones={["alfaNumerico"]} required={true} value={suceso?.relato} onChange={onChange} name={"relato"} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Subtipo:</Typography>} />
          <SmallInput restricciones={["alfaNumerico"]} required={true} value={suceso?.subtipo} onChange={onChange} name={"subtipo"} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha ingreso CUCC:</Typography>} />
          <ListItemText>{convertDateUtc(suceso?.carga_fechahora)}</ListItemText>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha Ult. Modif.:</Typography>} />
          <ListItemText>{convertDateUtc(suceso?.fecha_ultmodif)}</ListItemText>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
            Cancelar
          </ButtonIcon>
          <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSave} styleButton={{ marginLeft: "10px" }}>
            Guardar
          </ButtonIcon>
        </Grid>
      </Grid>
    </List>
  );
};

export default FormNewSuceso;
