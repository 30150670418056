import { CorteDeCalleTemplate } from "../../components/templates";

const CorteDeCallesScreen = () => {
  return (
    <div>
      <CorteDeCalleTemplate />
    </div>
  );
};

export default CorteDeCallesScreen;
