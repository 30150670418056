import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetAdminMutation } from "../../../store/api/endpoints/adminApiSlice";
import { alone } from "../../../store/slices/admin";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { EditarAdmin } from "../../organism/admin/AdmitEdit";

const AdminFichaTemplate = () => {
  const dispatch = useDispatch();
  const [admin, setadmin] = useState([]);
  const [editting, seteditting] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getCliente, reponseGetCliente] = useGetAdminMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (reponseGetCliente.isSuccess) {
      setadmin(reponseGetCliente?.data?.data);
      dispatch(alone(reponseGetCliente?.data?.data));
    }
  }, [reponseGetCliente.isSuccess]);

  useEffect(() => {
    getCliente(id);
  }, []);

  if (reponseGetCliente.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_ADMIN} />}
        titulo={"Ficha de " + admin?.nombre + " " + admin?.apellido}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_ADMIN}?id=${admin?._id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
      />
      <EditarAdmin id={id} editting={false} />
      <br />
    </Container>
  );
};

export default AdminFichaTemplate;
