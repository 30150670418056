import { SucesoFichaTemplate } from "../../components/templates";

const SucesoFichaScreen = () => {
  return (
    <div>
      <SucesoFichaTemplate />
    </div>
  );
};

export default SucesoFichaScreen;
