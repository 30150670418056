import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsAdmin } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetClientesMutation } from "../../../store/api/endpoints/adminApiSlice";
import { clean, getListAdmin, getPagination, list, setPagination } from "../../../store/slices/admin";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRowAdmin } from "../../molecule";
import { HeaderVista } from "../../organism";

const AdminTemplate = () => {
  const [getClientes, { isLoading, isSuccess, data: dataUsers }] = useGetClientesMutation();
  const storePage = useSelector(getPagination, shallowEqual);
  const admins = useSelector(getListAdmin, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      const data = dataUsers.data;
      dispatch(setPagination(data));
      dispatch(list(data.docs));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    let newPagination = {
      cantRows: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };

    getClientes({ ...newPagination });
  }, [storePage.page, storePage.limit]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_ADMIN} children="Nuevo" />} titulo="Listado de administradores" />
      <DataTable columns={columnsAdmin} rows={admins} RowComponent={TableRowAdmin} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_ADMIN}`} />
      <br />
    </Container>
  );
};

export default AdminTemplate;
