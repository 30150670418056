import SearchIcon from "@mui/icons-material/Search";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { ButtonIcon } from "../atoms";

export const TableRowUser = ({ row }) => (
  <tr style={{ height: "10%" }}>
    <td align="right" style={{ display: "flex", alignItems: "center" }}>
      <p>{row.nombre}</p>
    </td>
    <td align="left">
      <p>{row.apellido}</p>
    </td>
    <td align="left">
      <p>{row.comuna}</p>
    </td>
    <td align="left">
      <p>{row.email}</p>
    </td>
    <td align="left">
      <ButtonIcon arialLabel="detailsUser" startIcon={<SearchIcon />} to={`${PRIVATE_ROUTES.FICHA_USER}?id=${row._id}`} />
    </td>
  </tr>
);
