import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteAdminMutation, useGetAdminMutation, useUpdateAdminMutation } from "../../../store/api/endpoints/adminApiSlice";
import { alone } from "../../../store/slices/admin";
import { openFeedback } from "../../../store/slices/feedback";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { EditarAdmin } from "../../organism/admin/AdmitEdit";

const AdminEditTemplate = () => {
  const dispatch = useDispatch();
  const [admin, setadmin] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getCliente, reponseGetCliente] = useGetAdminMutation();
  const [deleteAdmin, responseDelete] = useDeleteAdminMutation();
  const [updateAdmin, responseUpdateAdmin] = useUpdateAdminMutation();

  const id = searchParams.get("id");

  const handleActivate = () => {
    let data = { ...admin, activo: true };
    if (data) {
      updateAdmin({ id, datos: data });
    }
  };

  useEffect(() => {
    if (reponseGetCliente.isSuccess) {
      setadmin(reponseGetCliente?.data?.data);
      dispatch(alone(reponseGetCliente?.data?.data));
    }
    if (responseUpdateAdmin.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el usuario!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_USERS);
    }
  }, [reponseGetCliente.isSuccess, responseUpdateAdmin.isSuccess]);

  useEffect(() => {
    getCliente(id);
  }, []);

  if (reponseGetCliente.isLoading || responseUpdateAdmin.isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_ADMIN}?id=${admin?._id}`} />}
        titulo={"Ficha de " + admin?.nombre + " " + admin?.apellido}
        end={
          <>
            {admin?.activo ? (
              <ConfirmDelete id={id} slice={deleteAdmin} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_ADMIN} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <EditarAdmin id={id} editting={true} />
      <br />
    </Container>
  );
};

export default AdminEditTemplate;
