import { CorteDeCalleEmpresasEditTemplate } from "../../components/templates";

const CorteDeCallesEmpresasEditScreen = () => {
  return (
    <div>
      <CorteDeCalleEmpresasEditTemplate />
    </div>
  );
};

export default CorteDeCallesEmpresasEditScreen;
