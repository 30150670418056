import { Container } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { EstadoNuevo } from "../../organism/estado/EstadoNuevo";
import { useCreateStateEmpresasMutation } from "../../../store/api/endpoints/stateEmpresasApiSlice";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { openFeedback } from "../../../store/slices/feedback";
import { useDispatch } from "react-redux";

const EstadoEmpresasNewTemplate = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createState, responseState] = useCreateStateEmpresasMutation();

  useEffect(() => {
    if (responseState.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el estado!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_STATES_EMPRESAS);
    }

    if (responseState.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseState?.error?.data?.message,
        })
      );
    }
  }, [responseState.isSuccess, responseState.isError]);

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_STATES_EMPRESAS} />} titulo={"Crear estado empresa"} end={<></>} />
      <EstadoNuevo id={id} createState={createState} responseState={responseState} isEmpresa={true} />
      <br />
    </Container>
  );
};

export default EstadoEmpresasNewTemplate;
