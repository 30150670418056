import { Container } from "@mui/material";

import { EmpresaNuevo, HeaderVista } from "../../components/organism";
import { Volver } from "../../components/molecule";

const EmpresaNewScreen = () => {
  return (
    <Container maxWidth="lg">
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear empresa"} end={<></>} />
      <EmpresaNuevo />
      <br />
    </Container>
  );
};

export default EmpresaNewScreen;
