import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";

export default function CheckboxList({ opciones, elegidos, handleChange, name, titulo, disabled }) {
  const [checked, setChecked] = useState(elegidos);
  const handleToggle = (value) => () => {
    if (opciones) {
      const currentIndex = checked?.indexOf(value);
      const newChecked = currentIndex === -1 ? [...checked, value] : checked.filter((item) => item !== value);

      setChecked(newChecked);
      handleChange(newChecked, name);
    }
  };

  useEffect(() => {
    setChecked(elegidos);
  }, [elegidos]);

  return (
    <>
      <h4 align="center">{titulo}</h4>
      <div>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {opciones.map((value, index) => {
            const labelId = `checkbox-list-label-${value.codigo}`;
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton role={undefined} onClick={handleToggle(value?.codigo)} dense disabled={disabled}>
                  <ListItemIcon>
                    <Checkbox edge="start" checked={checked?.indexOf(value?.codigo) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.nombre} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </>
  );
}
