import { TipoNombreCalleNewTemplate } from "../../components/templates";

const TipoDanioNewScreen = () => {
  return (
    <div>
      <TipoNombreCalleNewTemplate />
    </div>
  );
};

export default TipoDanioNewScreen;
