import { TipoEspecieArbolEditTemplate } from "../../components/templates";

const TipoEspecieArbolEditScreen = () => {
  return (
    <div>
      <TipoEspecieArbolEditTemplate />
    </div>
  );
};

export default TipoEspecieArbolEditScreen;
