import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonIcon } from "../../components/atoms";
import ConfirmDelete from "../../components/elementos/ConfirmDelete2";
import { Loading, Volver } from "../../components/molecule";
import { FormEmpresaEdit, HeaderVista } from "../../components/organism";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { useDeleteEmpresaMutation, useGetEmpresaMutation, useUpdateEmpresaMutation } from "../../store/api/endpoints/empresaApiSlice";
import { alone } from "../../store/slices/empresa";
import { openFeedback } from "../../store/slices/feedback";

const EmpresaEditScreen = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [empresa, setempresa] = useState([]);
  const [getEmpresa, reponsegetEmpresa] = useGetEmpresaMutation();
  const [deleteEmpresa, responseDelete] = useDeleteEmpresaMutation();
  const [update, responseUpdate] = useUpdateEmpresaMutation();

  const handleActivate = () => {
    let data = { ...empresa, activo: true };
    if (data) {
      update({ id, data });
    }
  };

  useEffect(() => {
    if (reponsegetEmpresa.isSuccess) {
      setempresa(reponsegetEmpresa?.data?.data);
      dispatch(alone(reponsegetEmpresa?.data?.data));
    }
  }, [reponsegetEmpresa.isSuccess]);

  useEffect(() => {
    if (responseUpdate.isSuccess) navigate(`${PRIVATE_ROUTES.FICHA_EMPRESA}?id=${id}`);
    if (responseUpdate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdate.error?.data?.message ?? "Error al actualizar los datos del usuario.",
        })
      );
    }
  }, [responseUpdate.isSuccess, responseUpdate.isError]);

  useEffect(() => {
    getEmpresa(id);
  }, []);

  if (reponsegetEmpresa.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" />}
        titulo={"Ficha de " + empresa?.nombre}
        end={
          <>
            {empresa?.activo ? (
              <ConfirmDelete id={id} slice={deleteEmpresa} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_EMPRESA} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <FormEmpresaEdit id={id} editting={true} />
      <br />
    </Container>
  );
};
export default EmpresaEditScreen;
