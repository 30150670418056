import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteUserMutation, useGetUserMutation, useUpdateUserMutation } from "../../../store/api/endpoints/userApiSlice";
import { useGetEmpresasMutation } from "../../../store/api/endpoints/empresaApiSlice";

import { alone, clean, setEmpresasAsociadas } from "../../../store/slices/user";
import { setList } from "../../../store/slices/empresa";

import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { FormUserEdit } from "../../organism/user/FormUserEdit";
import { openFeedback } from "../../../store/slices/feedback";

const UserEditTemplate = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [user, setuser] = useState([]);
  const [getCliente, reponseGetCliente] = useGetUserMutation();
  const [deleteAdmin, responseDelete] = useDeleteUserMutation();
  const [updateUser, responseUpdate] = useUpdateUserMutation();
  const [getEmpresas, reponseEmpresas] = useGetEmpresasMutation();

  const handleActivate = () => {
    let data = { ...user, activo: true };
    if (data) {
      updateUser({ id, data });
    }
  };

  useEffect(() => {
    if (reponseGetCliente.isSuccess) {
      setuser(reponseGetCliente?.data?.data);
      dispatch(alone(reponseGetCliente?.data?.data));
      if (reponseGetCliente?.data?.data?.empresas_asociadas) {
        dispatch(setEmpresasAsociadas(reponseGetCliente?.data?.data?.empresas_asociadas?.map((el) => el._id)));
      }
    }
    if (reponseEmpresas.isSuccess) {
      let newEmpresas = reponseEmpresas?.data?.data?.docs.map((el) => ({
        codigo: el._id,
        nombre: el.nombre,
      }));
      dispatch(setList(newEmpresas));
    }
  }, [reponseGetCliente.isSuccess, reponseEmpresas.isSuccess]);

  useEffect(() => {
    if (responseUpdate.isSuccess) navigate(PRIVATE_ROUTES.LIST_USERS);
    if (responseUpdate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdate.error?.data?.message ?? "Error al actualizar los datos del usuario.",
        })
      );
    }
  }, [responseUpdate.isSuccess, responseUpdate.isError]);

  useEffect(() => {
    getCliente(id);
    getEmpresas({ page: 0, cantRows: 50 });
  }, []);

  if (reponseGetCliente.isLoading || responseUpdate.isLoading || reponseEmpresas.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" />}
        titulo={"Ficha de " + user?.nombre + " " + user?.apellido}
        end={
          <>
            {user?.activo ? (
              <ConfirmDelete id={id} slice={deleteAdmin} response={responseDelete} returnUrl={PRIVATE_ROUTES.LIST_USERS} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <FormUserEdit id={id} editting={true} />
      <br />
    </Container>
  );
};

export default UserEditTemplate;
