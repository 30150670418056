/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useCreateSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { alone, clean } from "../../../store/slices/sucesos";
import FormNewSuceso from "./FormNewSuceso";

const TIPO_VIA_PUBLICA = "Vía pública";

export const SucesoNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createSuceso, responseCreateSuceso] = useCreateSucesoMutation();

  useEffect(() => {
    if (responseCreateSuceso.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el suceso!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_SUCESOS);
    }

    if (responseCreateSuceso.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreateSuceso?.error?.data?.message,
        })
      );
    }
  }, [responseCreateSuceso.isSuccess, responseCreateSuceso.isError]);

  useEffect(() => {
    dispatch(clean());
    dispatch(alone({ tipo: TIPO_VIA_PUBLICA }));
  }, []);

  if (responseCreateSuceso.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormNewSuceso id="" />
      <br />
    </>
  );
};
