import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectSmall({ lista, label, value, onChange, name }) {
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value, name);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select labelId="demo-select-small-label" id="demo-select-small" value={value} onChange={handleChange} variant="standard">
        {lista.map((item, index) => (
          <MenuItem sx={{ marginTop: 0 }} value={item.key} key={index} onClick={handleChange}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
