import { SucesoNewTemplate } from "../../components/templates";

const SucesoNewScreen = () => {
  return (
    <div>
      <SucesoNewTemplate />
    </div>
  );
};

export default SucesoNewScreen;
