import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTipoDanioMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { alone } from "../../../store/slices/tipoCortes";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import FormCortesDeCalle from "../../organism/CortesDeCalles/FormCortesDeCalle";

const TipoDanioFichaTemplate = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getCortes, responseCorte] = useGetTipoDanioMutation();
  const id = searchParams.get("id");

  useEffect(() => {
    if (responseCorte.isSuccess) {
      dispatch(alone(responseCorte?.data?.data));
    }
  }, [responseCorte.isSuccess]);

  useEffect(() => {
    getCortes(id);
  }, []);

  if (responseCorte.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS} />}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_TIPO_DAÑO_EMPRESAS}?id=${id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
        titulo={"Ficha tipo daño"}
      />
      <FormCortesDeCalle id={id} editting={false} />
      <br />
    </Container>
  );
};

export default TipoDanioFichaTemplate;
