import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsGeneral } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTiposEspecieArbolMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { alone, getListEspecies, getPagination, listEspecies, setPagination } from "../../../store/slices/tipoEspecieArbol";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRow } from "../../molecule";
import { HeaderVista } from "../../organism";

const TipoEspecieArbolTemplate = () => {
  const dispatch = useDispatch();
  const [getEspecies, { isLoading, isSuccess, data: dataEspecies }] = useGetTiposEspecieArbolMutation();
  const storePage = useSelector(getPagination, shallowEqual);
  const listTipoArbol = useSelector(getListEspecies, shallowEqual);
  useEffect(() => {
    if (isSuccess) {
      const data = dataEspecies.data;
      dispatch(listEspecies(data.docs));
      dispatch(setPagination(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    dispatch(alone());
  }, []);

  useEffect(() => {
    let newPagination = {
      limit: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getEspecies({ ...newPagination });
  }, [storePage.page, storePage.limit]);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_ESPECIE_ARBOL} children="Nuevo" />} titulo="Especie arbóreas" />
      <DataTable columns={columnsGeneral} rows={listTipoArbol} RowComponent={TableRow} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_ESPECIE_ARBOL}`} />
      <br />
    </Container>
  );
};

export default TipoEspecieArbolTemplate;
