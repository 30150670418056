import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { style } from "./styles";

const LIstDatosEmpresa = ({ empresa, comunasAsociadas }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <h3 style={{ textAlign: "center" }}>Datos empresa</h3>
        <List sx={style} aria-label="mailbox folders">
          <Grid item xs={12} md={12}>
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Nombre:</Typography>} />
              <ListItemText>{empresa?.nombre}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Email:</Typography>} />
              <ListItemText>{empresa?.email}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Telefono:</Typography>} />
              <ListItemText>{empresa?.telefono}</ListItemText>
            </ListItem>
          </Grid>
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3 style={{ textAlign: "center" }}>Comunas asociadas</h3>
        <List sx={style} aria-label="mailbox folders">
          <Grid item xs={12} md={12}>
            {comunasAsociadas?.map((el, index) => (
              <div key={index}>
                {index !== 0 && <Divider component="li" />}
                <ListItem>
                  <ListItemText>Comuna {el}</ListItemText>
                </ListItem>
              </div>
            ))}
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

export default LIstDatosEmpresa;
