import { UserTemplate } from "../../components/templates";

const UserScreen = () => {
  return (
    <main>
      <UserTemplate />
    </main>
  );
};

export default UserScreen;
