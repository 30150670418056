import "../../assets/css/HeaderVista.css";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Divider } from "../atoms";
import { memo } from "react";

const HeaderVista = memo(({ end, titulo, start }) => {
  return (
    <Container component="main" maxWidth="lg">
      <br />
      <Grid container spacing={2} className="encabezadoHeader">
        <Grid item xs={12} md={4} className="volver">
          {start}
        </Grid>
        <Grid item xs={12} md={4} className="titulo">
          {titulo}
        </Grid>
        <Grid item xs={12} md={4} className="botones">
          {end}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Container>
  );
});

export default HeaderVista;
