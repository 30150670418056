import { Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { CorteDeCalleNueva } from "../../organism/CortesDeCalles/CorteDeCalleNueva";

const CorteDeCalleEmpresasNewTemplate = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear corte de calle empresas"} end={<></>} />
      <CorteDeCalleNueva id={id} isEmpresa={true} />
      <br />
    </Container>
  );
};

export default CorteDeCalleEmpresasNewTemplate;
