import { SucesosTemplate } from "../../components/templates";

const SucesosScreen = () => {
  return (
    <div>
      <SucesosTemplate />
    </div>
  );
};

export default SucesosScreen;
