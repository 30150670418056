import { IncidenciasFichaTemplate } from "../../components/templates";

const IncidenciasFichaScreen = () => {
  return (
    <div>
      <IncidenciasFichaTemplate />
    </div>
  );
};

export default IncidenciasFichaScreen;
