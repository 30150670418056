import { SucesoEditTemplate } from "../../components/templates";

const SucesoEditScreen = () => {
  return (
    <div>
      <SucesoEditTemplate />
    </div>
  );
};

export default SucesoEditScreen;
