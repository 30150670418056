import MiPerfilTemplate from "../components/templates/MiPerfilTemplate";

const MiPerfilScreen = () => {
  return (
    <div>
      <MiPerfilTemplate />
    </div>
  );
};

export default MiPerfilScreen;
